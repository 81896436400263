import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { Flex, Heading, Text, Box, css, Image } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { CTALink } from '../components/ctaLink';
import { FeatureImageHeader } from '../components/FeatureImageHeader';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { gMapStyleArr } from '../utils/gMapStyleArr';

interface Props {}

export const query = graphql`
	query {
		prismic {
			# Fed into BlogPreviewPanel
			allBlog_posts(first: 7) {
				edges {
					node {
						title
						cover_photo
						sub_title
						author
						_linkType
						_meta {
							uid
							lastPublicationDate
						}
					}
				}
			}
		}
	}
`;

const contact = ({ data }: Props) => {
	const prismicContent = data.prismic!;
	let blogPosts = prismicContent.allBlog_posts!.edges;

	let transformedBlogPosts = blogPosts.map(({ node }) => ({
		author: node.author,
		bodyPreview: node.sub_title[0].text,
		img: node.cover_photo!.url,
		title: node.title[0].text,
		link: '/blog/' + node._meta.uid,
	}));

	const containerStyle = {
		width: '100%',
		height: '500px',
	};

	const center = {
		lat: -33.863152,
		lng: 151.0718821,
	};

	const mapOptions = {
		fullscreenControl: false,
		streetViewControl: false,
		mapTypeControl: false,
		styles: gMapStyleArr,
	};

	return (
		<PageLayout>
			<Flex flexDir="column">
				<Box w="100">
					<Flex
						flexDir={{ base: 'column', lg: 'row' }}
						w="100%"
						bg="#f7f0e3"
						alignItems="center"
					>
						{/* <Image
							// src={}
							mt={{ base: 20, lg: 0 }}
							w={{ base: '80%', lg: '50%' }}
						/> */}
						<LoadScript googleMapsApiKey="AIzaSyB_S5S8mLLz7XPsLIicKqNE9LOqoawua-k">
							<GoogleMap
								mapContainerStyle={containerStyle}
								center={center}
								zoom={15}
								options={mapOptions}
								// clickableIcons={false}
							>
								<InfoWindow position={center} options={{ closable: false }}>
									<div>
										<Heading color="primary.300" fontSize="sm">
											Thompson Fresh
										</Heading>
									</div>
								</InfoWindow>
							</GoogleMap>
						</LoadScript>
						<Box
							px={{ base: 0, lg: 20 }}
							my={{ base: 10, lg: 0 }}
							w={{ base: '80%', lg: '50%' }}
							css={css`
								h1 {
									font-size: 3rem;
									font-weight: 700;
								}
								max-width: 75ch;
							`}
						>
							<h1>Contact Us</h1>
							<p>
								Thompson Fresh Produce Pty Ltd
								<br />
								Farm Produce Merchants
								<br />
								Stand 213-214 C Shed
								<br />
								PO Box 110
								<br />
								Sydney Markets NSW 2129
								<br />
								<br />
								Phone: <a href="tel:+02 9746 8883">02 9746 8883</a>
								<br />
								<br />
								Fax: <a href="tel:+02 9746 2420">02 9746 2420</a>
								<br />
								<br />
								Email:&nbsp;
								<a href="mailto:office@thompsonfresh.com.au">
									office@thompsonfresh.com.au
								</a>
							</p>
							{/* <h1>{title}</h1>
				{bodyText} */}
							{/* <StyledRichText render={el.text} linkResolver={linkResolver} /> */}
						</Box>
					</Flex>
					<Box>{/* content */}</Box>
				</Box>
			</Flex>
		</PageLayout>
	);
};

export default contact;
